import { Box, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { appointmentHeaders_style } from './style';

export function AppointmentHeaders(props) {
  const {
    profileIcon,
    title = '',
    subText = '',
    age = '',
    isFollowUP = '',
    isReferral = '',
    countyCode = '',
    date = '',
  } = props;

  return (
    <Box>
      <Box>
        <Grid container>
          <Grid item xs={4}>
            <Box>
              <Box sx={appointmentHeaders_style.avatarSx}>{profileIcon}</Box>
              {isFollowUP ? (
                <Chip
                  label={isFollowUP}
                  size="small"
                  variant="outlined"
                  sx={appointmentHeaders_style.followUpSx}
                />
              ) : (
                ''
              )}
              {isReferral ? (
                <Chip
                  label={isReferral}
                  size="small"
                  variant="outlined"
                  sx={appointmentHeaders_style.referralSx}
                />
              ) : (
                ''
              )}
            </Box>
          </Grid>
          <Grid item xs={8} display="flex">
            <Grid container alignSelf="center">
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={{
                    fontSize: '14px',
                    color: 'text.primary',
                    fontWeight: 500,
                  }}
                >
                  {countyCode} {title}
                </Typography>
              </Grid>
              {subText && (
                <Grid item xs={12} display="flex" justifyContent="start">
                  <span style={{ alignItems: 'center' }}>
                    {subText}
                    {age && <span>, {age} Yrs</span>}
                    {date && <span style={{ fontSize: '10px' }}>{date}</span>}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

AppointmentHeaders.propTypes = {
  profileIcon: PropTypes.string,
  title: PropTypes.string,
  subText: PropTypes.string,
  age: PropTypes.string,
  isFollowUP: PropTypes.string,
  isReferral: PropTypes.string,
  countyCode: PropTypes.string,
  date: PropTypes.string,
};
